//@ts-nocheck

import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { colors, desktopBreakpoint, desktopVW, mobileVW } from '../../../styles';
import '../../../styles/index.css';
import { ItemTypes } from '../ItemTypes';
import axios from 'axios';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';
import Product from '../Product';
import { useDrag } from 'react-dnd';
import {
  useAddItemsToCart,
  useCartItems,
  useCheckoutUrl,
  useGlobalDictionaryQuery,
  useLocale,
  useProtectionProducts,
} from '../../../hooks';
import formatPrice from '../../../utils/formatPrice';
import ItemsCheckout from '../ItemsCheckout';
import ProductTemplates from '../ProductTemplates';
import { title } from 'process';
import ItemsCheckoutNormal from '../ItemsCheckoutNormal';
import { SettingsContext, StoreContext } from '../../../contexts';
import { decode } from 'shopify-gid';
import AlertPinterest from '../AlertPinterest';
import LinkTool from '../../shared/LinkTool';

type Props = {
  data: any;
  amount: any;
  template: any;
  tutorial: boolean;
  addTemplate: any;
  isMobile: boolean;
  setNewItemAdded: Function;
  pinterest: string;
  alertPinterestTitle: string;
  alertPinterestHeading: string;
  alertPinterestDescription: string;
  alertPinterestUrl: string;
  textSidebarBeforePrice: string;
  textSidebarAfterPrice: string;
  textSidebarBeforeProduct: string;
  textSidebarScreenViewOrderDetail: string;
  iconCategoryShelfmateApp: any;
};

export default function HeaderMobile({
  data,
  template,
  amount,
  addTemplate,
  isMobile,
  setNewItemAdded,
  pinterest,
  tutorial,
  alertPinterestTitle,
  alertPinterestHeading,
  alertPinterestDescription,
  alertPinterestUrl,
  textSidebarBeforePrice,
  textSidebarAfterPrice,
  textSidebarBeforeProduct,
  textSidebarScreenViewOrderDetail,
  iconCategoryShelfmateApp,
}: Props) {
  const {
    setIsCartOpen,
  }: {
    setIsCartOpen: any;
  } = useContext(SettingsContext);

  const { client } = useContext(StoreContext);
  const cartData = useCartItems();
  const protectionProducts = useProtectionProducts();
  const checkoutUrl = useCheckoutUrl();
  const locale = useLocale();
  const addItems = useAddItemsToCart();
  const dictionary = useGlobalDictionaryQuery();
  const [activeIndex, setActiveIndex] = useState(null);
  const [subTypeSelected, setSubTypeSelected] = useState('Basic');
  const [subTypeSelectedTemplate, setSubTypeSelectedTemplate] = useState('');
  const [typeSelected, setTypeSelected] = useState('');
  const [openOrderDetail, setOpenOrderDetail] = useState(false);
  const [limtiShowProduct, setLimitShowProduct] = useState(3);
  const [totalProduct, setTotalProduct] = useState(0);
  const [codeVoucher, setCodeVoucer] = useState('');
  const [allItems, setAllItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [protectionPrice, setProtectionPrice] = useState(0);
  const [showAlertPinterest, setShowAlertInterest] = useState(true);
  const priceRef = useRef(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const [categoryShelfmate, setCategoryShelfmate] = useState([]);

  const handleChange = (index: any) => {
    setActiveIndex(index);
  };
  let arrowRight =
    'https://images.ctfassets.net/hhqcfrz88uh8/3bO25y3YyQIqQ2WHOmrAhF/101a4b52dae2ce738bb80775550b983a/Vector__5_.svg';
  let arraowDown =
    'https://images.ctfassets.net/hhqcfrz88uh8/3Fu0DAlFRGNSlE40WSUePK/dae583722355255da0612791b4b43b1a/Vector__4_.svg';
  let logo =
    'https://images.ctfassets.net/hhqcfrz88uh8/5i2wmzdYdhYIDzGOca5N3h/ab933da45e0d1492412ab207552f9f51/download.svg';
  let arrowBack =
    'https://cdn.shopify.com/s/files/1/0473/9570/0896/files/checkout-logo-transparent_png.png?v=1719559804';
  let arraowUp =
    'https://images.ctfassets.net/hhqcfrz88uh8/78rWajNJQjZNiwzNjNHXNL/01fd8c966a5bd42cb3e68cfb2a3073d4/arrow-up__2_.png';
  let arraowDown2 =
    'https://images.ctfassets.net/hhqcfrz88uh8/76i0GAdJtVkyiCh1b2pMOM/23f29daa2254f40f19b8f9450907c11b/arrow-down__1_.png';
  let arrowRight2 =
    'https://images.ctfassets.net/hhqcfrz88uh8/5i2wmzdYdhYIDzGOca5N3h/ab933da45e0d1492412ab207552f9f51/download.svg';
  let iconMenuTemplate =
    'https://images.ctfassets.net/hhqcfrz88uh8/2N2eWC7wlbJnc4peSuYDlh/2413f60210c31f817c7ed3e1ed717615/Group_1__1_.png';
  let iconClose =
    'https://images.ctfassets.net/hhqcfrz88uh8/6I5KELYmBurzEGGoHVuqdW/f263e49aeb2a51439462fcb5f3ec186f/icon_close.svg';

  const onCheckout = async () => {
    let checkout = checkoutUrl;
    let combineData = filterDataDuplicate;

    if (combineData?.length === 0) {
      setIsCartOpen(true);
    } else {
      const shelfmateNewItem = [];
      const cartItemIds = new Set(cartData?.map(data => data.variant.id));

      combineData.forEach(item => {
        let id = decode(item.shopifyData.variants[0].id).id;
        let quaantityItemInCart =
          cartData.find(data => data.variant.id.includes(id))?.quantity || 0;
        let newQuantity = item.quantity - quaantityItemInCart;

        if (newQuantity > 0) {
          shelfmateNewItem.push({
            variantId: item.shopifyData.variants[0].id,
            quantity: newQuantity,
          });
        }
      });

      if (shelfmateNewItem.length > 0) {
        await addItems(shelfmateNewItem);
      }
      if (cartData?.length == 0) {
        window.location.href = `${checkout}&locale=${locale}`;
      } else {
        setIsCartOpen(true);
      }
    }
  };

  function removeDuplicates(array, key) {
    const unique = [];
    const seen = new Set();

    for (const item of array) {
      if (!seen.has(item[key])) {
        unique.push(item);
        seen.add(item[key]);
      }
    }

    return unique;
  }

  const checkAlreadyInCart = (item: any) => {
    if (cartData?.length > 0) {
      let check = cartData.filter((data: any) => data?.variant?.id.includes(item));
      if (check.length === 0) {
        return 0;
      } else {
        let data = filterDataDuplicate;
        let calc = data?.filter((p: any) => decode(p.shopifyData.variants[0].id).id === item);

        if (calc?.length > 0) {
          if (check[0]?.quantity - calc[0]?.quantity > 0) {
            return check[0].quantity - calc[0]?.quantity;
          } else {
            return 0;
          }
        } else {
          return check[0]?.quantity;
        }
      }
    } else {
      return 0;
    }
  };

  function filterData(item: any) {
    const result = {};
    item?.forEach(item => {
      if (item?.shelfmateGroupType === null) {
        return;
      }
      const key = `${item?.shelfmateGroupType}`;
      if (!result[key]) {
        result[key] = {
          type: item?.shelfmateGroupType,
          title: item?.titleToShow,
          data: [],
        };
      }
      result[key]?.data?.push(item);
    });
    return Object.values(result).map((entry: any) => ({
      type: entry?.type,
      title: entry?.title,
      data: entry?.data,
    }));
  }

  const addItem = item => {
    if (isMobile) {
      let width = 200;
      let height = 200;
      if (item?.shelfmateImageTransparent?.title) {
        let size = item?.shelfmateImageTransparent?.title
          .trim()
          .split('#')[1]
          .split('x');
        width = size[0] * 2;
        height = size[1] * 2;
      }
      let data = {
        name: item?.title,
        width: width,
        height: height,
        image: item?.shelfmateImageTransparent
          ? item?.shelfmateImageTransparent?.fluid?.src
          : item?.thumbnailImages[1]?.fluid?.src,
        left: 0,
        top: 0,
        shopifyData: item?.shopifyData,
        additional: 0,
      };
      setNewItemAdded(data);
      setNewItemAdded(null);
    }
  };

  const Products = (data: any) => (
    <ProductWrapper>
      {data?.data?.map((item: any, i: number) => {
        let width = 200;
        let height = 200;
        if (item?.shelfmateImageTransparent?.title) {
          let size = item?.shelfmateImageTransparent?.title
            .trim()
            .split('#')[1]
            .split('x');
          width = size[0] * 3;
          height = size[1] * 3;
        }

        const [{ isDragging }, dragRef, preview] = useDrag({
          type: ItemTypes.BOX,
          item: {
            name: item?.title,
            width: width,
            height: height,
            image: item?.shelfmateImageTransparent
              ? item?.shelfmateImageTransparent?.fluid?.src
              : item?.thumbnailImages[1]?.fluid?.src,
            left: 0,
            top: 0,
            shopifyData: item?.shopifyData,
            additional: 0,
          },
          collect: monitor => ({
            isDragging: monitor.isDragging(),
          }),
        });

        let quantity = checkAlreadyInCart(decode(item?.shopifyData?.variants[0].id).id);

        return (
          <Product
            onClick={() => {
              addItem(item);
              setTypeSelected('');
            }}
            shopifyData={item?.shopifyData?.variants[0]}
            key={i}
            ref={dragRef}
            title={item?.titleToShow}
            image={
              item?.shelfmateImageTransparent
                ? item?.shelfmateImageTransparent?.fluid?.src
                : item?.thumbnailImages[1]?.fluid?.src
            }
            preview={preview}
            isDrag={isDragging}
            width={width}
            height={height}
            alreadyItem={quantity}
          />
        );
      })}
    </ProductWrapper>
  );

  const ProductsTemplate = (data: any) => {
    return (
      <ProductWrapper>
        {data?.data?.map((item: any, i: number) => {
          const title = item?.templateName?.split('(')[0];
          const dataCode = JSON.parse(item.codeTemplate.codeTemplate);

          return (
            <ProductTemplates
              key={i}
              title={title}
              image={item?.imageTemplate?.fluid?.src}
              price={0}
              data={dataCode}
              onClick={(e: any) => {
                addTemplate(e);
                setTypeSelected('');
              }}
            />
          );
        })}
      </ProductWrapper>
    );
  };

  const DataAllItems = () => {
    return (
      <AllData>
        {allItems?.map((item: any, i) => {
          if (i <= limtiShowProduct - 1) {
            return (
              <ItemsCheckout
                key={i}
                shopifyData={item?.shopifyData}
                title={item?.title}
                image={item?.image}
                quantity={item?.quantity}
                shelfmate={true}
              />
            );
          }
        })}

        {allItems?.length > 3 && (
          <ShowMore onClick={() => setLimitShowProduct(limtiShowProduct === 3 ? 100 : 3)}>
            {limtiShowProduct === 3 ? 'Show More' : 'Show Less'}
            <ImageShowMore src={limtiShowProduct === 3 ? arraowDown2 : arraowUp} alt="arrow" />
          </ShowMore>
        )}
      </AllData>
    );
  };

  const filterDataDuplicate = useMemo(() => {
    const result = new Map();
    if (amount.data) {
      Object.keys(amount?.data).forEach(key => {
        const { title, shopifyData, image } = amount.data[key];
        if (!result.has(title)) {
          result.set(title, { title, quantity: 0, shopifyData, image });
        }
        result.get(title).quantity += 1;
      });
      return Array.from(result.values());
    } else {
      return [];
    }
  }, [amount?.data]);

  const calculateTotalPrice = useCallback(() => {
    if (filterDataDuplicate) {
      return filterDataDuplicate.reduce((total, item) => {
        return total + parseInt(item.shopifyData.variants[0].price) * item.quantity;
      }, 0);
    } else {
      return 0;
    }
  }, [filterDataDuplicate]);

  useEffect(() => {
    const totalPriceTemp = calculateTotalPrice();
    setAllItems(filterDataDuplicate);
    setTotalPrice(totalPriceTemp);
  }, [filterDataDuplicate, calculateTotalPrice]);

  useEffect(() => {
    setCategoryShelfmate(removeDuplicates(template, 'categoryTemplate'));

    if (subTypeSelectedTemplate === '' && template?.length > 0) {
      setSubTypeSelectedTemplate(template[0]?.categoryTemplate);
    }
  }, [template]);

  useEffect(() => {
    if (containerRef.current) {
      const container = containerRef.current;
      container.scrollLeft = container.scrollWidth - container.clientWidth;
    }
  }, [tutorial]);
  return (
    <>
      <WrapperHeader>
        <LinkTool to="/shelfmate">
          <ButtonBackWrapper>
            <ButtonBack>
              <img src={logo} alt="back" width="100" height="100" />
            </ButtonBack>
            <Logo src={arrowBack} alt="dbodhi" width="100" height="100" />
          </ButtonBackWrapper>
        </LinkTool>

        <div id="step-mobile-1">
          <TextUnderHeader>{textSidebarBeforeProduct}</TextUnderHeader>
          <MenuWrapper
            ref={containerRef}
            className="overflow-transparent"
            allowZeroExpanded
            onChange={handleChange}
          >
            {data?.map((item: any, i: number) => {
              let image = iconCategoryShelfmateApp?.filter((d: any) => d?.title === item?.type)[0];
              let subType = filterData(item?.data).sort((a, b) => a.type.localeCompare(b?.type));

              return (
                <div key={i}>
                  <Menu onClick={() => setTypeSelected(item?.type)}>{item?.type}</Menu>
                  <Submenu active={item?.type === typeSelected}>
                    <ButtonBackWrapper>
                      <ButtonBack onClick={() => setTypeSelected('')}>
                        <img src={logo} alt="back" width="100" height="100" />
                      </ButtonBack>
                      <ItemButtonLeftContent>
                        <ItemButtonLeftTitle>{item?.type}</ItemButtonLeftTitle>
                        <ItemButtonLeftCount>
                          {item?.data?.length + ' Products'}
                        </ItemButtonLeftCount>
                      </ItemButtonLeftContent>
                    </ButtonBackWrapper>
                    {subType?.length > 1 ? (
                      <ShelfmateGroupType>
                        <ShelfmateGroupTypeHeader>
                          {subType?.map((data: any, i: number) => {
                            return (
                              <ShelfmateGroupTypetitle
                                key={i}
                                // @ts-ignore
                                active={data?.type === subTypeSelected}
                                onClick={() => setSubTypeSelected(data?.type)}
                              >
                                {data?.type}
                              </ShelfmateGroupTypetitle>
                            );
                          })}
                        </ShelfmateGroupTypeHeader>
                        <Products
                          data={item?.data?.filter(
                            (d: any) => d?.shelfmateGroupType === subTypeSelected
                          )}
                        />
                      </ShelfmateGroupType>
                    ) : (
                      <Products data={item?.data} />
                    )}
                  </Submenu>
                </div>
              );
            })}
            {/* Template */}
            <>
              <Menu id="step-mobile-2" onClick={() => setTypeSelected('template')}>
                Setup Template
              </Menu>
              <Submenu active={typeSelected === 'template'}>
                <ButtonBackWrapper>
                  <ButtonBack onClick={() => setTypeSelected('')}>
                    <img src={logo} alt="back" width="100" height="100" />
                  </ButtonBack>
                  <ItemButtonLeftContent>
                    <ItemButtonLeftTitle>Setup Template</ItemButtonLeftTitle>
                    <ItemButtonLeftCount> {template?.length + ' Setup'}</ItemButtonLeftCount>
                  </ItemButtonLeftContent>
                </ButtonBackWrapper>
                {categoryShelfmate?.length > 1 ? (
                  <ShelfmateGroupType>
                    <ShelfmateGroupTypeHeader>
                      {categoryShelfmate?.map((data: any, i: number) => {
                        return (
                          <ShelfmateGroupTypetitle
                            key={i}
                            // @ts-ignore
                            active={data?.categoryTemplate === subTypeSelectedTemplate}
                            onClick={() => setSubTypeSelectedTemplate(data?.categoryTemplate)}
                          >
                            {data?.categoryTemplate}
                          </ShelfmateGroupTypetitle>
                        );
                      })}
                    </ShelfmateGroupTypeHeader>
                    <ProductsTemplate
                      data={template?.filter(
                        (d: any) => d?.categoryTemplate === subTypeSelectedTemplate
                      )}
                    />
                  </ShelfmateGroupType>
                ) : (
                  <></>
                )}
              </Submenu>
            </>
            {showAlertPinterest && (
              <AlertPinterest
                title={alertPinterestTitle}
                heading={alertPinterestHeading}
                description={alertPinterestDescription}
                url={alertPinterestUrl}
                onClose={() => setShowAlertInterest(false)}
              />
            )}
          </MenuWrapper>
        </div>
      </WrapperHeader>
      <WrapperFooter>
        <Header>
          <HeaderLeft>
            <div>
              <Title>{textSidebarBeforePrice}</Title>
              <HeaderLeftInner>
                <BoxPrice>
                  <Price>
                    {formatPrice(totalPrice + protectionPrice - totalPrice * amount?.discount, '€')}
                  </Price>
                </BoxPrice>
                {amount?.discount != 0 && (
                  <BoxPrice>
                    <Price discount={true}>{formatPrice(totalPrice + protectionPrice, '€')}</Price>
                  </BoxPrice>
                )}
              </HeaderLeftInner>
            </div>
            <TextHeaderBottom onClick={() => setOpenOrderDetail(true)}>
              {textSidebarAfterPrice}
              <ImageHeaderBottom
                // @ts-ignore
                src={arrowRight}
                alt={'What would you like to customize'}
              />
            </TextHeaderBottom>
          </HeaderLeft>
          <HeaderRight>
            <ButtonCheckout
              // @ts-ignore
              onClick={() => onCheckout()}
            >
              Checkout{' '}
              <ImageButtonCheckout
                // @ts-ignore
                src={arrowRight2}
                alt="back"
              />
            </ButtonCheckout>
          </HeaderRight>
        </Header>
        <OrderDetail active={openOrderDetail}>
          <OrderDetailheader>
            <OrderDetailTitle>{textSidebarScreenViewOrderDetail}</OrderDetailTitle>
            <StyledImgClose
              src={iconClose}
              alt="arrow close"
              width="100"
              height="100"
              onClick={() => setOpenOrderDetail(false)}
            />
          </OrderDetailheader>
          {amount?.data && <TotalItems>Total {allItems?.length} product</TotalItems>}

          <DataAllItems />

          <BoxPriceFooter>
            <TitleFooter>Sub Total</TitleFooter>
            <PriceFooter>{formatPrice(totalPrice + protectionPrice, '€')}</PriceFooter>
          </BoxPriceFooter>
          {amount?.discount != 0 && (
            <BoxPriceFooter>
              <TitleFooter>Discount</TitleFooter>
              <PriceFooter>-{formatPrice(totalPrice * amount?.discount, '€')}</PriceFooter>
            </BoxPriceFooter>
          )}
          {/* @ts-ignore */}
          <BoxPriceFooter border={true}>
            <TitleFooter>TOTAL</TitleFooter>
            <PriceFooter>
              {formatPrice(totalPrice + protectionPrice - totalPrice * amount?.discount, '€')}
            </PriceFooter>
          </BoxPriceFooter>
          <FooterButtonCheckOut onClick={() => onCheckout()}>
            Checkout
            <ImageButtonCheckout src={arrowRight2} alt="back" />
          </FooterButtonCheckOut>
        </OrderDetail>
      </WrapperFooter>
    </>
  );
}
const Submenu = styled.div`
  background-color: ${colors.white};
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: auto;
  min-height: ${mobileVW(200)};
  width: 100%;
  transition: 0.3s ease-in-out;
  transform: ${({
    // @ts-ignore
    active,
  }) => (active ? ' translateX(0) ' : 'translateX(-100%)')};
  padding: ${mobileVW(16)} ${mobileVW(10)} ${mobileVW(16)} ${mobileVW(16)};
`;

const MenuWrapper = styled.div`
  display: flex;
  width: 100%;
  overflow-x: auto;
`;

const Menu = styled.div`
  font-size: ${mobileVW(12)};
  padding: ${mobileVW(10)} ${mobileVW(12)};
  border: ${mobileVW(1.5)} solid #c1bfbb;
  margin-right: ${mobileVW(12)};
  white-space: nowrap;
`;

const ImageShowMore = styled.img`
  width: ${mobileVW(12)};
  margin-left: ${mobileVW(6.5)};
  ${desktopBreakpoint} {
    width: ${desktopVW(9)};
    margin-left: ${desktopVW(6.5)};
  }
`;

const TotalItems = styled.div`
  font-size: ${mobileVW(10)};
  padding: ${mobileVW(16)} ${mobileVW(16)} ${mobileVW(5)} ${mobileVW(16)};
  ${desktopBreakpoint} {
    font-size: ${desktopVW(14)};
    padding: ${desktopVW(20)} ${desktopVW(20)} ${desktopVW(10)} ${desktopVW(20)};
  }
`;

const FooterButtonCheckOut = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s;
  width: calc(100% - ${mobileVW(32)});
  height: ${mobileVW(44)};
  font-size: ${mobileVW(14)};
  border: ${mobileVW(1)} solid #c5b9a3;
  margin: 0 ${mobileVW(16)};
`;

const BoxPriceFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${mobileVW(20)} 0 ${mobileVW(20)};
  margin-bottom: ${mobileVW(16)};
  border-top: ${({
    // @ts-ignore
    border,
  }) => (border ? mobileVW(1) + ' solid ' + colors.cappuccino : 'none')};
  padding-top: ${({
    // @ts-ignore
    border,
  }) => (border ? mobileVW(20) : '0')};
  font-size: ${({
    // @ts-ignore
    border,
  }) => (border ? mobileVW(14) : mobileVW(14))};
  ${desktopBreakpoint} {
    padding: 0 ${desktopVW(20)} 0 ${desktopVW(20)};
    margin-bottom: ${desktopVW(16)};
    border-top: ${({
      // @ts-ignore
      border,
    }) => (border ? desktopVW(1) + ' solid ' + colors.cappuccino : 'none')};
    padding-top: ${({
      // @ts-ignore
      border,
    }) => (border ? desktopVW(20) : '0')};
    font-size: ${({
      // @ts-ignore
      border,
    }) => (border ? desktopVW(18) : desktopVW(16))};
  }
`;

const TitleFooter = styled.div`
  ${desktopBreakpoint} {
  }
`;
const PriceFooter = styled.div`
  ${desktopBreakpoint} {
    font-weight: 600;
  }
`;

const WrapperHeader = styled.div`
  position: fixed;
  background-color: #fff;
  width: 100%;
  top: 0;
  left: 0;
  padding: ${mobileVW(16)} ${mobileVW(10)} ${mobileVW(16)} ${mobileVW(16)};
`;
const WrapperFooter = styled.div`
  position: fixed;
  background-color: #fff;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: ${mobileVW(16)} ${mobileVW(10)} ${mobileVW(16)} ${mobileVW(16)};
`;

const AccordionItemButtonLeft = styled.div`
  display: flex;
`;
const ItemButtonLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${mobileVW(10)};
`;
const ItemButtonLeftTitle = styled.div`
  display: flex;
  font-size: ${mobileVW(14)};
  color: ${colors.black};
`;

const ItemButtonLeftCount = styled.div`
  font-size: ${mobileVW(12)};
  color: #707070;
`;

const AccordionItemButtonLeftImage = styled.div`
  display: flex;
  width: ${desktopVW(24)};
  height: ${desktopVW(24)};
  background-color: transparent;
  border: ${desktopVW(1)} solid ${colors.cappuccino};
  margin-top: ${desktopVW(2)};
`;

const ProductWrapper = styled.div`
  display: flex;
  margin-top: ${mobileVW(16)};
  flex-wrap: wrap;
`;

const Header = styled.div`
  display: flex;
  flex-direction:column;
  height:${mobileVW(139)}
  align-items: end;
`;
const HeaderLeft = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;
const HeaderLeftInner = styled.div`
  display: flex;
`;
const HeaderRight = styled.div`
  display: flex;
`;
const Title = styled.div`
  display: flex;
  font-size: ${mobileVW(12)};
`;
const BoxPrice = styled.div`
  display: flex;
  align-items: center;
`;

const Price = styled.div`
  display: flex;
  font-size: ${mobileVW(24)};
  font-weight: ${({
    // @ts-ignore
    discount,
  }) => (discount ? '400' : '700')};
  opacity: ${({
    // @ts-ignore
    discount,
  }) => (discount ? '0.6' : '1')};

  text-decoration: ${({
    // @ts-ignore
    discount,
  }) => (discount ? 'line-through' : 'none')};
  margin: ${mobileVW(7)} ${mobileVW(15)} ${mobileVW(7)} 0;
`;
const TextHeaderBottom = styled.div`
  display: flex;
  font-size: ${mobileVW(12)};
  align-items: center;
  cursor: pointer;
`;
const ImageHeaderBottom = styled.img`
  display: flex;
  width: ${mobileVW(5)};
  margin-left: ${mobileVW(8)};
`;
const TextUnderHeader = styled.div`
  font-size: ${mobileVW(14)};
  padding-bottom: ${mobileVW(14)};
  font-weight: 500;
`;
const ButtonCheckout = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 100%;
  height: ${mobileVW(44)};
  font-size: ${mobileVW(14)};
  align-items: center;
  transition: 0.2s;
  padding: ${mobileVW(14)};
  color: ${colors.black};
  border: ${mobileVW(1)} solid #c5b9a3 !important;
`;

const ImageButtonCheckout = styled.img`
  display: flex;
  transform: rotate(180deg);
  height: ${mobileVW(15)};
  margin-left: ${mobileVW(10)};
  color: ${colors.cappuccino};
  opacity: ${({
    // @ts-ignore
    disable,
  }) => (disable ? '0.2' : '1')};
  ${desktopBreakpoint} {
    height: ${desktopVW(15)};
    margin-left: ${desktopVW(10)};
    opacity: ${({
      // @ts-ignore
      disable,
    }) => (disable ? '0.2' : '1')};
  }
`;

const ImageItem = styled.img`
  ${desktopBreakpoint} {
    width ${desktopVW(24)};
    height: auto;
    object-fit:contain;
  }
`;
const ShelfmateGroupType = styled.div`
  display: flex;
  flex-direction: column;
`;
const ShelfmateGroupTypeWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const ShelfmateGroupTypeHeader = styled.div`
  display: flex;
  ${desktopBreakpoint} {
    font-size: ${mobileVW(12)};
    margin: -${mobileVW(20)} 0 ${mobileVW(20)} ${mobileVW(40)};
  }
`;
const ShelfmateGroupTypetitle = styled.div`
  display: flex;
  padding-right: ${mobileVW(15)};
  text-transform: uppercase;
  cursor: pointer;
  font-weight: ${({
    // @ts-ignore
    active,
  }) => (active ? '600' : '400')};
  text-decoration: ${({
    // @ts-ignore
    active,
  }) => (active ? 'underline' : 'none')};
`;
const ShelfmateGroupTypeContent = styled.div`
  display: flex;
`;
const InputContainer = styled.div`
  position: relative;
  ${desktopBreakpoint} {
    margin: ${desktopVW(20)} ${desktopVW(20)} ${desktopVW(40)} ${desktopVW(20)};
  }
  padding-right: ${desktopVW(60)};
`;

const StyledInput = styled.input`
  padding: ${desktopVW(12)} ${desktopVW(12)} ${desktopVW(12)} ${desktopVW(12)};
  border: ${desktopVW(1)} solid #c1bfbb;
  width: 100%;
  outline: none;
  font-family: 'messinaMono';

  &:focus + label,
  &:not(:placeholder-shown) + label {
    top: -${desktopVW(10)};
    left: ${desktopVW(14)};
    font-size: ${desktopVW(14)};
    color: ${colors.black};
    background-color: ${colors.white};
    z-index: 1;
  }
  ${desktopBreakpoint} {
    font-size: ${desktopVW(16)};
  }
`;

const Label = styled.label`
  position: absolute;
  top: ${desktopVW(13)};
  left: ${desktopVW(14)};
  font-size: ${desktopVW(14)};
  transition: 0.2s;
  font-family: 'messinaMono';
  color: #c1bfbb;
  z-index: -1;
`;

const ButtonApplyCoupon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: ${desktopVW(0)};
  right: ${desktopVW(0)};
  height: 100%;
  cursor: pointer;
  background-color: ${colors.black};
  ${desktopBreakpoint} {
    padding: 0 ${desktopVW(27.5)};
    color: ${colors.white};
  }
`;
const OrderDetail = styled.div`
  position: fixed;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: ${colors.white};
  top: 0;
  right: 0;
  z-index: 2;
  transition: transform 0.3s ease;
  transform: ${({
    // @ts-ignore
    active,
  }) => (active ? 'translateX(0)' : 'translateX(100%)')};
`;

const OrderDetailheader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 350;
  padding: ${desktopVW(20)};
`;

const OrderDetailTitle = styled.div`
  font-size: ${mobileVW(16)};
  padding-left: ${mobileVW(10)};
  margin-top: ${mobileVW(10)};
`;

const StyledImgClose = styled.img`
  cursor: pointer;
  height: ${mobileVW(18)};
  width: ${mobileVW(18)};
  margin-right: ${mobileVW(6)};
`;

const ShowMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: ${mobileVW(14)};
  margin-top: ${mobileVW(13)};
  ${desktopBreakpoint} {
    font-size: ${desktopVW(14)};
    margin-top: ${desktopVW(17)};
  }
`;

const AllData = styled.div`
  margin-bottom: ${mobileVW(90)};
  ${desktopBreakpoint} {
    margin-bottom: ${desktopVW(20)};
  }
`;

const ButtonBackWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${mobileVW(16)};
`;
const ButtonBack = styled.button`
  background-color: #fff;
  width: ${mobileVW(32)};
  height: ${mobileVW(32)};
  border-radius: ${mobileVW(16)};
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${mobileVW(1)} solid #c5b9a3;

  img {
    width: ${mobileVW(15)};
  }
`;
const Logo = styled.img`
  width: ${mobileVW(93)};
  height: auto;
  margin-left: ${mobileVW(12)};
`;
