import React from 'react';
import styled from 'styled-components';
import { colors, desktopBreakpoint, desktopVW, mobileVW } from '../../styles';

type Props = {
  onClose: Function;
  isMobile: boolean;
  show: boolean;
  shelfmatePopUpDiscount: any;
};

export default function AlertDiscount({ onClose, isMobile, show, shelfmatePopUpDiscount }: Props) {
  let title = shelfmatePopUpDiscount.title?.split('#');
  let description = shelfmatePopUpDiscount.description?.split('/n');

  return (
    <Wrapper show={show}>
      <Close
        onClick={() => onClose()}
        src={
          'https://images.ctfassets.net/hhqcfrz88uh8/6I5KELYmBurzEGGoHVuqdW/f263e49aeb2a51439462fcb5f3ec186f/icon_close.svg'
        }
        alt="arrow close"
        width="100"
        height="100"
      />
      {!isMobile && <Image src={shelfmatePopUpDiscount.fluid.src} alt="alert" />}
      <Content>
        <Heading>
          {title[0]} <Highlight>{title[1]}</Highlight>
        </Heading>
        <Subheading>
          {description?.map((item: any, i: number) => (
            <Description>{item}</Description>
          ))}
        </Subheading>
      </Content>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  font-family: 'messinaMono';
  background-color: white;
  position: absolute;
  display: flex;
  z-index: 10;
  width: 95%;
  height: ${mobileVW(105)};
  bottom: ${mobileVW(40)};
  border: ${mobileVW(1)} solid #c5b9a3;
  margin-left: 2.5%;
  transition: transform 0.8s ease-in;
  transform: ${({
    // @ts-ignore
    show,
  }) => (show ? 'translateY(0)' : 'translateY(200%)')};
  ${desktopBreakpoint} {
    width: ${desktopVW(900)};
    height: ${desktopVW(176)};
    bottom: ${desktopVW(90)};
    border: ${desktopVW(1)} solid #c5b9a3;
    margin-left: 0;
  }
`;

const Image = styled.img`
  width: 50%;
  height: 100%;
  object-fit: cover;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  padding: ${mobileVW(16)} ${mobileVW(12)};
  ${desktopBreakpoint} {
    width: 50%;
    padding: ${desktopVW(40)} ${desktopVW(25)};
  }
`;
const Heading = styled.div`
  font-size: ${mobileVW(14)};
  ${desktopBreakpoint} {
    font-size: ${desktopVW(18)};
  }
`;
const Subheading = styled.div`
  color: #707070;
  font-size: ${mobileVW(12)};
  margin-top: ${mobileVW(10)};
  ${desktopBreakpoint} {
    font-size: ${desktopVW(14)};
    margin-top: ${desktopVW(10)};
  }
`;
const Highlight = styled.span`
  color: ${colors.tomato};
`;

const Close = styled.img`
  display: flex;
  position: absolute;
  right: ${mobileVW(14)};
  top: ${mobileVW(14)};
  width: ${mobileVW(16)};
  height: ${mobileVW(16)};
  cursor: pointer;
  ${desktopBreakpoint} {
    right: ${desktopVW(14)};
    top: ${desktopVW(14)};
    width: ${desktopVW(16)};
    height: ${desktopVW(16)};
  }
`;

const Description = styled.div`
  display: block;
`;
