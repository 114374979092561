//@ts-nocheck

import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { colors, desktopBreakpoint, desktopVW } from '../../styles';
import '../../styles/index.css';
import { ItemTypes } from './ItemTypes';
import axios from 'axios';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import Product from './Product';
import { useDrag } from 'react-dnd';
import {
  useAddItemsToCart,
  useCartItems,
  useCheckoutUrl,
  useGlobalDictionaryQuery,
  useLocale,
  useProtectionProducts,
} from '../../hooks';
import formatPrice from '../../utils/formatPrice';
import ItemsCheckout from './ItemsCheckout';
import ProductTemplates from './ProductTemplates';
import { title } from 'process';
import ItemsCheckoutNormal from './ItemsCheckoutNormal';
import { SettingsContext, StoreContext } from '../../contexts';
import { decode } from 'shopify-gid';

type Props = {
  data: any;
  amount: any;
  template: any;
  tutorial: boolean;
  addTemplate: any;
  totalTemplate: number;
  setNewItemAdded: Function;
  tutorialShelfmate: boolean;
  textSidebarBeforePrice: string;
  textSidebarAfterPrice: string;
  textSidebarBeforeProduct: string;
  textSidebarScreenViewOrderDetail: string;
  iconCategoryShelfmateApp: any;
};

export default function Sidebar({
  data,
  template,
  amount,
  addTemplate,
  totalTemplate,
  setNewItemAdded,
  tutorialShelfmate,
  tutorial,
  textSidebarBeforePrice,
  textSidebarAfterPrice,
  textSidebarBeforeProduct,
  textSidebarScreenViewOrderDetail,
  iconCategoryShelfmateApp,
}: Props) {
  const {
    setIsCartOpen,
  }: {
    setIsCartOpen: any;
  } = useContext(SettingsContext);

  const { client } = useContext(StoreContext);
  const cartData = useCartItems();
  const protectionProducts = useProtectionProducts();
  const checkoutUrl = useCheckoutUrl();
  const locale = useLocale();
  const addItems = useAddItemsToCart();
  const dictionary = useGlobalDictionaryQuery();
  const [activeIndex, setActiveIndex] = useState([]);
  const [subTypeSelected, setSubTypeSelected] = useState('Basic');
  const [subTypeSelectedTemplate, setSubTypeSelectedTemplate] = useState('');
  const [openOrderDetail, setOpenOrderDetail] = useState(false);
  const [limtiShowProduct, setLimitShowProduct] = useState(3);
  const [totalProduct, setTotalProduct] = useState(0);
  const [codeVoucher, setCodeVoucer] = useState('');
  const [allItems, setAllItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [protectionPrice, setProtectionPrice] = useState(0);
  const [categoryShelfmate, setCategoryShelfmate] = useState([]);

  let arrowRight =
    'https://images.ctfassets.net/hhqcfrz88uh8/3bO25y3YyQIqQ2WHOmrAhF/101a4b52dae2ce738bb80775550b983a/Vector__5_.svg';
  let arraowDown =
    'https://images.ctfassets.net/hhqcfrz88uh8/3Fu0DAlFRGNSlE40WSUePK/dae583722355255da0612791b4b43b1a/Vector__4_.svg';
  let arraowUp =
    'https://images.ctfassets.net/hhqcfrz88uh8/78rWajNJQjZNiwzNjNHXNL/01fd8c966a5bd42cb3e68cfb2a3073d4/arrow-up__2_.png';
  let arraowDown2 =
    'https://images.ctfassets.net/hhqcfrz88uh8/76i0GAdJtVkyiCh1b2pMOM/23f29daa2254f40f19b8f9450907c11b/arrow-down__1_.png';
  let arrowRight2 =
    'https://images.ctfassets.net/hhqcfrz88uh8/5i2wmzdYdhYIDzGOca5N3h/ab933da45e0d1492412ab207552f9f51/download.svg';
  let iconMenuTemplate =
    'https://images.ctfassets.net/hhqcfrz88uh8/2N2eWC7wlbJnc4peSuYDlh/2413f60210c31f817c7ed3e1ed717615/Group_1__1_.png';
  let iconClose =
    'https://images.ctfassets.net/hhqcfrz88uh8/6I5KELYmBurzEGGoHVuqdW/f263e49aeb2a51439462fcb5f3ec186f/icon_close.svg';

  const handleChange = (index: any) => {
    setActiveIndex(index);
  };
  console.log('amount', amount);
  const onCheckout = async () => {
    let checkout = checkoutUrl;
    let combineData = filterDataDuplicate;

    if (combineData?.length === 0) {
      setIsCartOpen(true);
    } else {
      const shelfmateNewItem = [];
      const cartItemIds = new Set(cartData?.map(data => data.variant.id));

      combineData.forEach(item => {
        let id = decode(item.shopifyData.variants[0].id).id;
        let quaantityItemInCart =
          cartData.find(data => data.variant.id.includes(id))?.quantity || 0;
        let newQuantity = item.quantity - quaantityItemInCart;

        if (newQuantity > 0) {
          shelfmateNewItem.push({
            variantId: item.shopifyData.variants[0].id,
            quantity: newQuantity,
          });
        }
      });

      if (shelfmateNewItem.length > 0) {
        await addItems(shelfmateNewItem);
      }
      if (cartData?.length == 0) {
        window.location.href = `${checkout}&locale=${locale}`;
      } else {
        setIsCartOpen(true);
      }
    }
  };

  const filterDataDuplicate = useMemo(() => {
    const result = new Map();
    if (amount.data) {
      Object.keys(amount?.data).forEach(key => {
        const { title, shopifyData, image } = amount.data[key];
        if (!result.has(title)) {
          result.set(title, { title, quantity: 0, shopifyData, image });
        }
        result.get(title).quantity += 1;
      });
      return Array.from(result.values());
    } else {
      return [];
    }
  }, [amount?.data]);

  const checkAlreadyInCart = (item: any) => {
    if (cartData?.length > 0) {
      let check = cartData.filter((data: any) => data?.variant?.id.includes(item));
      if (check.length === 0) {
        return 0;
      } else {
        let data = filterDataDuplicate;
        let calc = data?.filter((p: any) => decode(p.shopifyData.variants[0].id).id === item);

        if (calc?.length > 0) {
          if (check[0]?.quantity - calc[0]?.quantity > 0) {
            return check[0].quantity - calc[0]?.quantity;
          } else {
            return 0;
          }
        } else {
          return check[0]?.quantity;
        }
      }
    } else {
      return 0;
    }
  };

  const getImageSize = item => {
    let width = 200;
    let height = 200;
    if (item?.shelfmateImageTransparent?.title) {
      const size = item?.shelfmateImageTransparent?.title
        .trim()
        .split('#')[1]
        .split('x');
      width = size[0] * 3;
      height = size[1] * 3;
    }
    return { width, height };
  };

  const addItem = item => {
    let width = 200;
    let height = 200;
    if (item?.shelfmateImageTransparent?.title) {
      let size = item?.shelfmateImageTransparent?.title
        .trim()
        .split('#')[1]
        .split('x');
      width = size[0] * 3;
      height = size[1] * 3;
    }
    let data = {
      name: item?.title,
      width: width,
      height: height,
      image: item?.shelfmateImageTransparent
        ? item?.shelfmateImageTransparent?.fluid?.src
        : item?.thumbnailImages[1]?.fluid?.src,
      left: 0,
      top: 0,
      shopifyData: item?.shopifyData,
      additional: 0,
    };
    setNewItemAdded(data);
    setNewItemAdded(null);
  };

  function removeDuplicates(array, key) {
    const unique = [];
    const seen = new Set();

    for (const item of array) {
      if (!seen.has(item[key])) {
        unique.push(item);
        seen.add(item[key]);
      }
    }

    return unique;
  }

  const Products = (data: any) => (
    <ProductWrapper>
      {data?.data?.map((item: any, i: number) => {
        const { width, height } = getImageSize(item);

        const [{ isDragging }, dragRef, preview] = useDrag({
          type: ItemTypes.BOX,
          item: {
            name: item?.title,
            width: width,
            height: height,
            image: item?.shelfmateImageTransparent
              ? item?.shelfmateImageTransparent?.fluid?.src
              : item?.thumbnailImages[1]?.fluid?.src,
            left: 0,
            top: 0,
            shopifyData: item?.shopifyData,
            additional: 0,
          },
          collect: monitor => ({
            isDragging: monitor.isDragging(),
          }),
        });

        let quantity = checkAlreadyInCart(decode(item?.shopifyData?.variants[0].id).id);

        return (
          <Product
            shopifyData={item?.shopifyData?.variants[0]}
            key={i}
            // ref={dragRef}
            title={item?.titleToShow}
            image={
              item?.shelfmateImageTransparent
                ? item?.shelfmateImageTransparent?.fluid?.src
                : item?.thumbnailImages[1]?.fluid?.src
            }
            preview={preview}
            isDrag={isDragging}
            width={width}
            height={height}
            alreadyItem={quantity}
            onClick={() => {
              addItem(item);
            }}
          />
        );
      })}
    </ProductWrapper>
  );

  const ProductsTemplate = (data: any) => {
    return (
      <ProductWrapper>
        {data?.data?.map((item: any, i: number) => {
          const title = item?.templateName?.split('(')[0];
          const dataCode = JSON.parse(item.codeTemplate.codeTemplate);

          return (
            <ProductTemplates
              key={i}
              title={title}
              image={item?.imageTemplate?.fluid?.src}
              price={0}
              data={dataCode}
              onClick={(e: any) => addTemplate(e)}
            />
          );
        })}
      </ProductWrapper>
    );
  };

  function filterData(item: any) {
    const result = {};
    item?.forEach(item => {
      if (item?.shelfmateGroupType === null) {
        return;
      }
      const key = `${item?.shelfmateGroupType}`;
      if (!result[key]) {
        result[key] = {
          type: item?.shelfmateGroupType,
          title: item?.titleToShow,
          data: [],
        };
      }
      result[key]?.data?.push(item);
    });
    return Object.values(result).map((entry: any) => ({
      type: entry?.type,
      title: entry?.title,
      data: entry?.data,
    }));
  }

  const DataAllItems = () => {
    return (
      <AllData>
        {allItems?.map((item: any, i) => {
          if (i <= limtiShowProduct - 1) {
            return (
              <ItemsCheckout
                key={i}
                shopifyData={item?.shopifyData}
                title={item?.title}
                image={item?.image}
                quantity={item?.quantity}
                shelfmate={true}
              />
            );
          }
        })}

        {allItems?.length > 3 && (
          <ShowMore onClick={() => setLimitShowProduct(limtiShowProduct === 3 ? 100 : 3)}>
            {limtiShowProduct === 3 ? 'Show More' : 'Show Less'}
            <ImageShowMore src={limtiShowProduct === 3 ? arraowDown2 : arraowUp} alt="arrow" />
          </ShowMore>
        )}
      </AllData>
    );
  };

  const calculateTotalPrice = useCallback(() => {
    if (filterDataDuplicate) {
      return filterDataDuplicate.reduce((total, item) => {
        return total + parseInt(item?.shopifyData?.variants[0]?.price) * item?.quantity;
      }, 0);
    } else {
      return 0;
    }
  }, [filterDataDuplicate]);

  useEffect(() => {
    const totalPriceTemp = calculateTotalPrice();
    setAllItems(filterDataDuplicate);
    setTotalPrice(totalPriceTemp);
  }, [filterDataDuplicate, calculateTotalPrice]);

  useEffect(() => {
    setCategoryShelfmate(removeDuplicates(template, 'categoryTemplate'));

    if (subTypeSelectedTemplate === '' && template?.length > 0) {
      setSubTypeSelectedTemplate(template[0]?.categoryTemplate);
    }
  }, [template]);

  const HeaderPrice = () => {
    if (amount.discount == 0) {
      return <Price>{formatPrice(totalPrice, '€')}</Price>;
    } else {
      return (
        <>
          <Price>{formatPrice(totalPrice - totalPrice * amount.discount, '€')}</Price>
          <Price discount={true}>{formatPrice(totalPrice, '€')}</Price>
        </>
      );
    }
  };

  return (
    <Wrapper>
      <Header>
        <HeaderLeft>
          <Title>{textSidebarBeforePrice}</Title>
          <BoxPrice>
            <HeaderPrice />
          </BoxPrice>
          <TextHeaderBottom onClick={() => setOpenOrderDetail(true)}>
            {textSidebarAfterPrice}
            <ImageHeaderBottom src={arrowRight} alt={'What would you like to customize'} />
          </TextHeaderBottom>
        </HeaderLeft>
        <HeaderRight>
          <ButtonCheckout onClick={() => onCheckout()}>
            Checkout <ImageButtonCheckout src={arrowRight2} alt="back" />
          </ButtonCheckout>
        </HeaderRight>
      </Header>
      <AccordionWrapper id="step-desktop-1" tutorial={tutorial}>
        <TextUnderHeader>{textSidebarBeforeProduct}</TextUnderHeader>
        <Accordion tutorial={tutorial} allowZeroExpanded onChange={handleChange}>
          {data?.map((item: any, i: number) => {
            let image = iconCategoryShelfmateApp?.filter((d: any) => d?.title === item?.type)[0];
            let subType = filterData(item?.data).sort((a, b) => a.type.localeCompare(b?.type));

            return (
              <AccordionItem key={i} style={{ pointerEvents: tutorial ? 'none' : 'visible' }}>
                <AccordionItemHeading className="border-2">
                  <AccordionItemButton>
                    <AccordionItemButtonLeft>
                      <ImageItem src={image?.fluid?.src} alt={image?.title} />
                      <AccordionItemButtonLeftContent>
                        <AccordionItemButtonLeftTitle>{item?.type}</AccordionItemButtonLeftTitle>
                        <AccordionItemButtonLeftCount>
                          {item?.data?.length + ' Products'}
                        </AccordionItemButtonLeftCount>
                      </AccordionItemButtonLeftContent>
                    </AccordionItemButtonLeft>
                    {activeIndex?.length ? (
                      <img
                        src={
                          // @ts-ignore
                          activeIndex[0]?.includes(i) ? arraowDown : arrowRight
                        }
                        alt={item?.type}
                      />
                    ) : (
                      <img src={arrowRight} alt={item?.type} />
                    )}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  {subType?.length > 1 ? (
                    <ShelfmateGroupType>
                      <ShelfmateGroupTypeHeader>
                        {subType?.map((data: any, i: number) => {
                          return (
                            <ShelfmateGroupTypetitle
                              key={i}
                              // @ts-ignore
                              active={data?.type === subTypeSelected}
                              onClick={() => setSubTypeSelected(data?.type)}
                            >
                              {data?.type}
                            </ShelfmateGroupTypetitle>
                          );
                        })}
                      </ShelfmateGroupTypeHeader>
                      <Products
                        data={item?.data?.filter(
                          (d: any) => d?.shelfmateGroupType === subTypeSelected
                        )}
                      />
                    </ShelfmateGroupType>
                  ) : (
                    <Products data={item?.data} />
                  )}
                </AccordionItemPanel>
              </AccordionItem>
            );
          })}

          <div id="step-desktop-2">
            <AccordionItem style={{ pointerEvents: tutorial ? 'none' : 'visible' }}>
              <AccordionItemHeading className="border-2">
                <AccordionItemButton>
                  <AccordionItemButtonLeft>
                    <ImageItem src={iconMenuTemplate} alt={'template'} />
                    <AccordionItemButtonLeftContent>
                      <AccordionItemButtonLeftTitle>Setup Template</AccordionItemButtonLeftTitle>
                      <AccordionItemButtonLeftCount>
                        {totalTemplate + ' Setup'}
                      </AccordionItemButtonLeftCount>
                    </AccordionItemButtonLeftContent>
                  </AccordionItemButtonLeft>

                  {activeIndex?.length ? (
                    <img
                      src={activeIndex[0]?.includes(data?.length) ? arraowDown : arrowRight}
                      alt={'template'}
                    />
                  ) : (
                    <img src={arrowRight} alt={'template'} />
                  )}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                {categoryShelfmate?.length > 0 ? (
                  <ShelfmateGroupType>
                    <ShelfmateGroupTypeHeader>
                      {categoryShelfmate?.map((data: any, i: number) => {
                        return (
                          <ShelfmateGroupTypetitle
                            key={i}
                            active={data?.categoryTemplate === subTypeSelectedTemplate}
                            onClick={() => setSubTypeSelectedTemplate(data?.categoryTemplate)}
                          >
                            {data?.categoryTemplate}
                          </ShelfmateGroupTypetitle>
                        );
                      })}
                    </ShelfmateGroupTypeHeader>
                    <ProductsTemplate
                      data={template?.filter(
                        (d: any) => d?.categoryTemplate === subTypeSelectedTemplate
                      )}
                    />
                  </ShelfmateGroupType>
                ) : (
                  <></>
                )}
              </AccordionItemPanel>
            </AccordionItem>
          </div>
        </Accordion>
        {tutorialShelfmate && (
          <FooterText absolute={activeIndex?.length === 0 ? true : false}>
            All rights reserved. © 2024 d-Bodhi
          </FooterText>
        )}
      </AccordionWrapper>

      <OrderDetail active={openOrderDetail}>
        <OrderDetailheader>
          <OrderDetailTitle>{textSidebarScreenViewOrderDetail}</OrderDetailTitle>
          <StyledImgClose
            src={iconClose}
            alt="arrow close"
            width="100"
            height="100"
            onClick={() => setOpenOrderDetail(false)}
          />
        </OrderDetailheader>
        {amount?.data && <TotalItems>Total {allItems?.length} product</TotalItems>}

        <DataAllItems />

        <BoxPriceFooter>
          <TitleFooter>Sub Total</TitleFooter>
          <PriceFooter>{formatPrice(totalPrice + protectionPrice, '€')}</PriceFooter>
        </BoxPriceFooter>
        {amount?.discount != 0 && (
          <BoxPriceFooter>
            <TitleFooter>Discount</TitleFooter>
            <PriceFooter>-{formatPrice(totalPrice * amount?.discount, '€')}</PriceFooter>
          </BoxPriceFooter>
        )}

        <BoxPriceFooter border={true}>
          <TitleFooter>TOTAL</TitleFooter>
          <PriceFooter>
            {formatPrice(totalPrice + protectionPrice - totalPrice * amount?.discount, '€')}
          </PriceFooter>
        </BoxPriceFooter>
        <FooterButtonCheckOut onClick={() => onCheckout()}>
          Checkout
          <ImageButtonCheckout src={arrowRight2} alt="back" />
        </FooterButtonCheckOut>
      </OrderDetail>
    </Wrapper>
  );
}

const ImageShowMore = styled.img`
  ${desktopBreakpoint} {
    width: ${desktopVW(9)};
    margin-left: ${desktopVW(6.5)};
  }
`;

const TotalItems = styled.div`
  ${desktopBreakpoint} {
    font-size: ${desktopVW(14)};
    padding: ${desktopVW(20)} ${desktopVW(20)} ${desktopVW(10)} ${desktopVW(20)};
  }
`;

const FooterButtonCheckOut = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s;
  ${desktopBreakpoint} {
    margin: 0 ${desktopVW(20)};
    width: calc(100% - ${desktopVW(40)});
    height: ${desktopVW(50)};
    border: ${desktopVW(1)} solid #c5b9a3;
  }

  &:hover {
    background-color: #c5b9a3;
    // color: ${colors.white};
  }
`;

const BoxPriceFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${desktopBreakpoint} {
    padding: 0 ${desktopVW(20)} 0 ${desktopVW(20)};
    margin-bottom: ${desktopVW(16)};
    border-top: ${({
      // @ts-ignore
      border,
    }) => (border ? desktopVW(1) + ' solid ' + colors.cappuccino : 'none')};
    padding-top: ${({
      // @ts-ignore
      border,
    }) => (border ? desktopVW(20) : '0')};
    font-size: ${({
      // @ts-ignore
      border,
    }) => (border ? desktopVW(18) : desktopVW(16))};
  }
`;

const TitleFooter = styled.div`
  ${desktopBreakpoint} {
  }
`;
const PriceFooter = styled.div`
  ${desktopBreakpoint} {
    font-weight: 600;
  }
`;

const Wrapper = styled.div`
  position: fixed;
  background-color: #fff;
  overflow-y: auto;
  width: 100%;
  top: 0;
  left: 0;
  ${desktopBreakpoint} {
    width: 33.33%;
    height: 100%;
    position: relative;
  }
`;

const AccordionItemButtonLeft = styled.div`
  display: flex;
`;
const AccordionItemButtonLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${desktopVW(10)};
`;
const AccordionItemButtonLeftTitle = styled.div`
  display: flex;
  font-size: ${desktopVW(16)};
  color: ${colors.black};
`;

const AccordionItemButtonLeftCount = styled.div`
  font-size: ${desktopVW(14)};
  color: #707070;
`;

const AccordionItemButtonLeftImage = styled.div`
  display: flex;
  width: ${desktopVW(24)};
  height: ${desktopVW(24)};
  background-color: transparent;
  border: ${desktopVW(1)} solid ${colors.cappuccino};
  margin-top: ${desktopVW(2)};
`;

const ProductWrapper = styled.div`
  display: flex;
  padding: 0 ${desktopVW(12)};
  flex-wrap: wrap;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 0.01vw solid #c1bfbb !important;
  padding: 1.2vw 2vw !important;
  align-items: end;
`;
const HeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
`;
const HeaderRight = styled.div`
  display: flex;
`;
const Title = styled.div`
  display: flex;
  font-size: ${desktopVW(16)};
`;
const BoxPrice = styled.div`
  display: flex;
  align-items: center;
`;

const Price = styled.div`
  display: flex;
  font-size: ${desktopVW(32)};
  font-weight: ${({
    // @ts-ignore
    discount,
  }) => (discount ? '400' : '700')};
  opacity: ${({
    // @ts-ignore
    discount,
  }) => (discount ? '0.6' : '1')};

  text-decoration: ${({
    // @ts-ignore
    discount,
  }) => (discount ? 'line-through' : 'none')};
  margin: ${desktopVW(7)} ${desktopVW(15)} ${desktopVW(7)} 0;
`;
const TextHeaderBottom = styled.div`
  display: flex;
  font-size: ${desktopVW(14)};
  align-items: center;
  cursor: pointer;
`;
const ImageHeaderBottom = styled.img`
  display: flex;
  width: ${desktopVW(6)};
  margin-left: ${desktopVW(6)};
`;
const TextUnderHeader = styled.div`
  font-size: ${desktopVW(18)};
  padding: 1.2vw 2vw 2vw 2vw !important;
  font-weight: 500;
`;
const ButtonCheckout = styled.div`
  cursor: pointer;
  display: flex;
  font-size: ${desktopVW(14)};
  align-items: center;
  transition: 0.2s;
  border: ${({
      // @ts-ignore
      disable,
    }) => (disable ? desktopVW(0.5) : desktopVW(1))}
    solid
    ${({
      // @ts-ignore
      disable,
    }) => (disable ? colors.cappuccino : '#c5b9a3')} !important;
  padding: ${desktopVW(14)};
  color: ${({
    // @ts-ignore
    disable,
  }) => (disable ? colors.cappuccino : colors.black)};

  &:hover {
    background-color: ${({
      // @ts-ignore
      disable,
    }) => (disable ? 'transparent' : '#c5b9a3')};
  }
`;

const ImageButtonCheckout = styled.img`
  display: flex;
  transform: rotate(180deg);
  height: ${desktopVW(15)};
  margin-left: ${desktopVW(10)};
  color: ${colors.cappuccino};
  opacity: ${({
    // @ts-ignore
    disable,
  }) => (disable ? '0.2' : '1')};
`;

const ImageItem = styled.img`
  ${desktopBreakpoint} {
    width ${desktopVW(24)};
    height: auto;
    object-fit:contain;
  }
`;
const ShelfmateGroupType = styled.div`
  display: flex;
  flex-direction: column;
`;
const ShelfmateGroupTypeWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const ShelfmateGroupTypeHeader = styled.div`
  display: flex;
  ${desktopBreakpoint} {
    font-size: ${desktopVW(14)};
    margin: -${desktopVW(20)} 0 ${desktopVW(20)} ${desktopVW(40)};
  }
`;
const ShelfmateGroupTypetitle = styled.div`
  display: flex;
  padding-right: ${desktopVW(15)};
  text-transform: uppercase;
  cursor: pointer;
  font-weight: ${({
    // @ts-ignore
    active,
  }) => (active ? '600' : '400')};
  text-decoration: ${({
    // @ts-ignore
    active,
  }) => (active ? 'underline' : 'none')};
`;
const ShelfmateGroupTypeContent = styled.div`
  display: flex;
`;
const InputContainer = styled.div`
  position: relative;
  ${desktopBreakpoint} {
    margin: ${desktopVW(20)} ${desktopVW(20)} ${desktopVW(40)} ${desktopVW(20)};
  }
  padding-right: ${desktopVW(60)};
`;

const StyledInput = styled.input`
  padding: ${desktopVW(12)} ${desktopVW(12)} ${desktopVW(12)} ${desktopVW(12)};
  border: ${desktopVW(1)} solid #c1bfbb;
  width: 100%;
  outline: none;
  font-family: 'messinaMono';

  &:focus + label,
  &:not(:placeholder-shown) + label {
    top: -${desktopVW(10)};
    left: ${desktopVW(14)};
    font-size: ${desktopVW(14)};
    color: ${colors.black};
    background-color: ${colors.white};
    z-index: 1;
  }
  ${desktopBreakpoint} {
    font-size: ${desktopVW(16)};
  }
`;

const Label = styled.label`
  position: absolute;
  top: ${desktopVW(13)};
  left: ${desktopVW(14)};
  font-size: ${desktopVW(14)};
  transition: 0.2s;
  font-family: 'messinaMono';
  color: #c1bfbb;
  z-index: -1;
`;

const ButtonApplyCoupon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: ${desktopVW(0)};
  right: ${desktopVW(0)};
  height: 100%;
  cursor: pointer;
  background-color: ${colors.black};
  ${desktopBreakpoint} {
    padding: 0 ${desktopVW(27.5)};
    color: ${colors.white};
  }
`;
const OrderDetail = styled.div`
  position: fixed;
  overflow-y: auto;
  width: 33.33%;
  height: 100%;
  background-color: ${colors.white};
  top: 0;
  right: 0;
  z-index: 2;
  transition: transform 0.3s ease;
  transform: ${({
    // @ts-ignore
    active,
  }) => (active ? 'translateX(0)' : 'translateX(100%)')};
  ${desktopBreakpoint} {
    padding-bottom: ${desktopVW(20)};
  }
`;

const OrderDetailheader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 350;
  ${desktopBreakpoint} {
    padding: ${desktopVW(20)};
  }
`;

const OrderDetailTitle = styled.div`
  ${desktopBreakpoint} {
    font-size: ${desktopVW(24)};
  }
`;

const StyledImgClose = styled.img`
  cursor: pointer;
  ${desktopBreakpoint} {
    width: ${desktopVW(12)};
    height: ${desktopVW(12)};
    margin-right: ${desktopVW(10)};
  }
`;

const ShowMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${desktopBreakpoint} {
    font-size: ${desktopVW(14)};
    margin-top: ${desktopVW(17)};
  }
`;

const AllData = styled.div`
  ${desktopBreakpoint} {
    margin-bottom: ${desktopVW(20)};
  }
`;

const AccordionWrapper = styled.div`
  margin: ${({
    // @ts-ignore
    tutorial,
  }) => (tutorial ? desktopVW(10) : '0')};
`;

const FooterText = styled.div`
  position: ${({
    // @ts-ignore
    absolute,
  }) => (absolute ? 'absolute' : 'relative')};
  display: flex;
  justify-content: center;
  bottom: ${desktopVW(30)};
  color: ${colors.cappuccino};
  font-size: ${desktopVW(12)};
  width: 96%;
  margin-top: ${({
    // @ts-ignore
    absolute,
  }) => (absolute ? desktopVW(0) : desktopVW(150))};
`;
