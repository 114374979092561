/* eslint-disable no-console */
// @ts-nocheck
import React, { useState, useContext, useEffect, useLayoutEffect, useMemo } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { SettingsContext } from '../contexts';
import CollectionHeroSection from '../components/collection/CollectionHeroSection';
import ShopTheLookMediaSection from '../components/collection/ShopTheLookMediaSection';
import CollectionContentBeforeProduct from '../components/collection/CollectionContentBeforeProduct';
import CollectionMediaSection from '../components/collection/CollectionMediaSection';
import CollectionGridSection from '../components/collection/CollectionGridSection';
import CollectionGaleryFilter from '../components/collection/CollectionGaleryFilter';
import SEO from '../components/shared/SEO';
import { useGlobalComponentsQuery, useGlobalDictionaryQuery } from '../hooks/index';
import { desktopBreakpoint, colors, desktopVW, mobileVW, zIndex } from '../styles/index';
import LinkTool from '../components/shared/LinkTool';
import { useLocale } from '../hooks/layout/useLocale';
import ShelfMateApp from '../components/shelfmateApp';
import { useLocation } from '@reach/router';

export interface collectionProps {
  data: {
    allContentfulProduct: {
      nodes: [];
    };
    allContentfulShelfmateApp: {
      edges: { node: any }[];
    };
    contentfulPageCollection: {
      title: string;
      seoTitle: string;
      seoDescription: string;
      artist: string;
      collectionAutometicByTagNew: boolean;
      heroImage: {
        fluid: string;
      };
      heroImageForMobile: {
        fluid: string;
      };
      heroTopText: string;
      heroBottomText: string;
      mediaSection: {
        videoFromYoutube: string;
        title: string;
        subtitle: string;
        paragraph: string;
        video: any;
        videoVimeo: string;
        videoTitle: string;
        image: {
          fluid: string;
        };
      };
      shopTheLook: {
        title: string;
        subtitle: string;
        paragraph: string;
        video: any;
        videoVimeo: string;
        videoTitle: string;
        image: {
          fluid: string;
        };
      };
      contentBeforeProduct: {
        video: any;
        videoVimeo: string;
        videoTitle: string;
        image: {
          fluid: string;
        };
      };
      pageWebApp: boolean;
      items: {
        length: number;
        map(arg0: (item: any) => void): unknown;
        title: string;
        slug: string;
        price: string;
        productCategory: {
          category: string;
        };
        thumbnailVariantsImages: [
          {
            variantTitle: string;
            images: [
              {
                title: string;
                fluid: string;
              }
            ];
          }
        ];
        shopifyData: {
          variants: {
            price: string;
            compare_at_price: string;
            inventory_quantity: string;
          };
        };
        productTag: string;
        thumbnailImages: {
          fluid: string;
        };
      };
    };
  };
  pageContext: any;
}

const Collection = ({
  data: { allContentfulProduct, contentfulPageCollection, allContentfulShelfmateApp },
  pageContext,
}: collectionProps) => {
  const {
    seoTitle,
    seoDescription,
    heroImage,
    heroImageForMobile,
    heroTopText,
    heroBottomText,
    mediaSection,
    items,
    pageWebApp,
    shopTheLook,
    contentBeforeProduct,
    collectionAutometicByTagNew,
  } = contentfulPageCollection;

  const {
    materialFilter,
    artistFilter,
    priceFilter,
    categoryFilter,
  }: {
    setMaterialFilter: any;
    setArtistFilter: any;
    categoryFilter: any;
  } = useContext(SettingsContext);

  const locale = useLocale();
  const dictionary = useGlobalDictionaryQuery();
  const location = useLocation();
  const global = useGlobalComponentsQuery();

  const [filterOpen, setFilterOpen] = useState(false);
  const [filterIncrement, setFilterIncrement] = useState(0);
  const [dataMaterial, setDataMaterial] = useState([]);
  const [dataCategory, setDataCategory] = useState([]);
  const [dataFilterMaterial, setDataFilterMaterial] = useState([]);
  const [dataFilterCategory, setDataFilterCategory] = useState([]);
  const [dataItems, setDataItems] = useState([]);
  const [updateItems, setUpdateItems] = useState([]);
  const [products, setProducts] = useState(items);
  const [isMobile, setIsMobile] = useState(true);
  const [isCollection, setCollection] = useState('second-chance');

  function sortRandomItems(item) {
    return item?.sort(() => Math.random() - 0.5);
  }

  function sortingAtoZBySlug(item) {
    return item?.sort((a, b) => a.slug.localeCompare(b.slug));
  }

  function areSlugsEqual(array1, array2) {
    const slugs1 = array1?.map(item => item.slug)?.sort();
    const slugs2 = array2?.map(item => item.slug)?.sort();

    return JSON.stringify(slugs1) === JSON.stringify(slugs2);
  }

  useEffect(() => {
    if (items?.length > 0) {
      items.map(item => {
        const materials = item?.material;
        const category = item?.productCategory?.category;
        setDataMaterial(dataMaterial => [...dataMaterial, ...materials]);
        setDataCategory(dataCategory => [...dataCategory, category]);
      });
    } else {
      setDataItems(items);
    }
  }, [items]);

  useEffect(() => {
    const materialsTitle = Array.from(new Set(dataMaterial));
    setDataFilterMaterial(materialsTitle);
    const categoryTitle = Array.from(new Set(dataCategory));
    setDataFilterCategory(categoryTitle);
  }, [dataMaterial, updateItems, dataCategory]);

  let dataFilterArtist = items?.map(item => item.artist);
  dataFilterArtist = dataFilterArtist?.filter(
    (item, index) => dataFilterArtist?.indexOf(item) === index && item != null
  );
  useLayoutEffect(() => {
    const isClient = typeof window === 'object';
    if (!isClient) return false;
    if (window.innerWidth >= 1024) setIsMobile(false);
  }, []);

  // Filter Product
  const filterByMaterial = array => {
    if (materialFilter.length > 0) {
      const data = array?.filter(item => {
        const material = item?.material.toString();
        for (let i = 0; i < materialFilter?.length; i++) {
          if (material.includes(materialFilter[i])) {
            return item;
          }
        }
      });
      return filterByArtist(data);
    }
    return filterByArtist(array);
  };

  const filterByArtist = array => {
    if (artistFilter.length > 0) {
      const filteredProdsByArtist = array.filter(product => artistFilter.includes(product.artist));
      filterByCategory(filteredProdsByArtist);
    } else {
      filterByCategory(array);
    }
  };

  const filterByCategory = array => {
    if (categoryFilter.length > 0) {
      const filteredProdsByCategory = array.filter(product =>
        categoryFilter.includes(product.productCategory.category)
      );
      return filterByPrice(filteredProdsByCategory);
    }
    return filterByPrice(array);
  };

  const filterByPrice = array => {
    const filteredProdsByPrice = array.filter(product => {
      if (parseFloat(product.shopifyData.variants[0].price) < parseFloat(priceFilter)) {
        return product;
      }
    });

    setProducts(filteredProdsByPrice);
  };

  useEffect(() => {
    if (
      seoTitle === 'dBodhi Art of Craftsmanship' ||
      seoTitle === 'Second chance' ||
      seoTitle === 'Tweedekans'
    ) {
      let prod = [];
      if (isCollection === 'second-chance') {
        let randomProduct = localStorage.getItem('products-second-chance');
        let compare = areSlugsEqual(
          sortingAtoZBySlug(sortRandomItems(items)),
          sortingAtoZBySlug(JSON.parse(randomProduct))
        );

        if (compare == false) {
          let itemsSecondChance = items.filter(
            (item: any) =>
              item.productTag === 'Second chance' ||
              item.productTag === 'Tweedekans' ||
              item.productTag === 'Discontinued Stock' ||
              item.productTag === 'Uitlopende voorraad'
          );

          let itemsShowroomModel = items.filter(
            (item: any) => item.productTag === 'Showroom Model'
          );

          let combineItems = [...itemsShowroomModel, ...sortRandomItems(itemsSecondChance)];

          prod = combineItems;
          localStorage.setItem('products-second-chance', JSON.stringify(combineItems));
        } else {
          prod = JSON.parse(randomProduct);
        }
      } else {
        prod = items;
      }

      filterByMaterial(prod);
    }
  }, [materialFilter, artistFilter, priceFilter, categoryFilter, isCollection]);

  const ImageBeforeProduct = (items: any) => (
    <ContentBeforeProductWrapper>
      {items?.map((item: any, i: number) => (
        <CollectionContentBeforeProduct mediaSection={item} key={i} />
      ))}
    </ContentBeforeProductWrapper>
  );

  useEffect(() => {
    if (location.pathname.includes('second-chance') || location.pathname.includes('tweedekans')) {
      setCollection('second-chance');
    } else if (location.pathname.includes('shelfmate-app')) {
      setCollection('shelfmate-app');
    } else if (
      location.pathname.includes('shelfmate') &&
      !location.pathname.includes('shelfmate-app')
    ) {
      setCollection('shelfmate');
    } else {
      setCollection('collection');
    }
  }, [location]);

  if (isCollection === 'shelfmate-app') {
    return <ShelfMateApp product={items} dataApp={allContentfulShelfmateApp.edges[0].node} />;
  }

  return (
    <>
      <SEO title={seoTitle} description={seoDescription} image={global?.seoImage.file.url} />
      <CollectionHeroSection
        heroImage={isMobile ? (heroImageForMobile ? heroImageForMobile : heroImage) : heroImage}
        heroTopText={heroTopText}
        heroBottomText={heroBottomText}
      />
      {mediaSection && (
        <CollectionMediaSection
          mediaSection={mediaSection}
          shelfmateApp={isCollection === 'shelfmate'}
          showButtonShelfmate={allContentfulShelfmateApp.edges[0].node?.showButtonShelfmateApp}
        />
      )}
      {seoTitle === 'dBodhi Art of Craftsmanship' ||
      seoTitle === 'Second chance' ||
      seoTitle === 'Tweedekans' ? (
        <FilterWrapper>
          <FilterButton
            type="button"
            aria-label="open filters"
            onClick={() => {
              setFilterOpen(true);
              setFilterIncrement(filterIncrement + 1);
            }}
          >
            Filters
          </FilterButton>
        </FilterWrapper>
      ) : (
        ''
      )}
      <CollectionGaleryFilter
        collection={seoTitle}
        showFilter={filterOpen}
        allProductsCategories={dataFilterCategory}
        allProductsMaterial={dataFilterMaterial}
        allProductsArtist={dataFilterArtist}
        filterIncrement={filterIncrement}
        getIncrementApply={e => {
          setUpdateItems(e);
        }}
      />
      {contentBeforeProduct && ImageBeforeProduct(contentBeforeProduct)}
      <CollectionGridSection items={products} />

      {shopTheLook?.length > 0 ? (
        <ItemShowTheLookWrapper>
          {shopTheLook?.map((item: any, i: number) => {
            return (
              <ItemShowTheLookContent>
                <ItemShowTheLook to={`/${item?.collection?.slug}/`} style={{ width: '101%' }}>
                  <ShopTheLookMediaSection mediaSection={item} key={i} />
                </ItemShowTheLook>
              </ItemShowTheLookContent>
            );
          })}
        </ItemShowTheLookWrapper>
      ) : null}
    </>
  );
};

const ItemShowTheLookContent = styled.div`
  width: 100%;
  ${desktopBreakpoint} {
    width: auto;
  }
`;

const ItemShowTheLookWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: ${mobileVW(50)} ${mobileVW(15)} ${mobileVW(0)} ${mobileVW(15)};

  ${desktopBreakpoint} {
    padding: ${desktopVW(78)} ${desktopVW(45)} ${desktopVW(58)} ${desktopVW(45)};
  }
`;

const ItemShowTheLook = styled(LinkTool)`
  width: 100%;
  ${desktopBreakpoint} {
    width: auto;
  }
`;

const FilterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 3vw;
`;

const FilterButton = styled.button`
  font-family: 'messinaMonoLight', 'Courier New', Courier, monospace;
  letter-spacing: -0.05em;
  border: ${mobileVW(1)} solid ${colors.black};
  margin-top: ${mobileVW(30)};
  padding: ${mobileVW(13)} ${mobileVW(21)};
  font-size: ${mobileVW(15)};

  ${desktopBreakpoint} {
    border: ${desktopVW(1)} solid ${colors.black};
    padding: ${desktopVW(13)} ${desktopVW(21)};
    font-size: ${desktopVW(15)};
    margin-bottom: ${desktopVW(20)};
    margin-top: 0;
  }
`;

const ContentBeforeProductWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: ${mobileVW(50)} ${mobileVW(20)} ${mobileVW(10)} ${mobileVW(20)};
  ${desktopBreakpoint} {
    flex-direction: row;
    padding: ${desktopVW(0)} ${desktopVW(60)} ${desktopVW(100)} ${desktopVW(60)};
  }
`;

export default Collection;

export const CollectionPageQuery = graphql`
  query CollectionPage($id: String!, $node_locale: String!) {
    allContentfulProduct(
      filter: {
        node_locale: { eq: $node_locale }
        productTag: { in: ["Second chance", "Tweedekans", "Showroom Model"] }
      }
    ) {
      nodes {
        node_locale
        title
        titleToShow
        slug
        artist
        material
        productCategory {
          category
        }
        shopifyData {
          variants {
            compare_at_price
            inventory_quantity
            price
            title
            sku
            id
            product_id
          }
        }
        productTag
        thumbnailImages {
          fluid {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
    allContentfulShelfmateApp(limit: 1) {
      edges {
        node {
          playgroundTitle {
            playgroundTitle
          }
          urlPinterest
          textButtonPinterestDesktop
          textButtonPinterestMobile
          titlePopupPinterest
          descriptionPopupPinterest
          textTitleModalAddTemplate
          textDescriptionModalAddTemplate
          textButtonAgreeModalAddTemplate
          textButtonDisagreeModalAddTemplate
          textTitleModalReset
          textDescriptionModalReset
          textButtonAgreeModalReset
          textButtonDisagreeModalReset
          textButtonForNavigationToOrderDetail
          textSidebarBeforePrice
          textSidebarAfterPrice
          textSidebarBeforeProduct
          textSidebarScreenViewOrderDetail
          textButtonReset
          textTitleTutorial1
          textDescriptionTutorial1
          textTitleTutorial2
          textDescriptionTutorial2
          templateShelfmateDesktop {
            templateName
            categoryTemplate
            imageTemplate {
              fluid {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            codeTemplate {
              codeTemplate
            }
          }
          templateShelfmateMobile {
            templateName
            categoryTemplate
            imageTemplate {
              fluid {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            codeTemplate {
              codeTemplate
            }
          }
          showButtonShelfmateApp
          shelfmateDiscount
          shelfmatePopUpDiscount {
            title
            description
            fluid {
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
          iconCategoryShelfmateApp {
            title
            fluid {
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
    }
    contentfulPageCollection(id: { eq: $id }) {
      title
      seoTitle
      seoDescription
      heroImage {
        fluid(maxWidth: 1920, quality: 50) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      heroImageForMobile {
        fluid(maxWidth: 1920, quality: 50) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      heroTopText
      heroBottomText
      mediaSection {
        title
        heading
        subtitle {
          subtitle
        }
        paragraph {
          paragraph
        }
        invertedLayout
        borderlessImage
        videoFromYoutube
        video {
          title
          file {
            url
          }
        }
        videoVimeo
        image {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        videoTitle
      }
      collectionAutometicByTagNew
      contentBeforeProduct {
        videoFromYoutube
        video {
          title
          file {
            url
          }
        }
        videoVimeo
        image {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      shopTheLook {
        title
        heading
        subtitle {
          subtitle
        }
        paragraph {
          paragraph
        }
        invertedLayout
        borderlessImage
        videoFromYoutube
        video {
          title
          file {
            url
          }
        }
        videoVimeo
        videoTitle
        image {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        collection {
          slug
        }
      }
      pageWebApp
      items {
        title
        titleToShow
        slug
        artist
        material
        productCategory {
          category
        }
        shelfmateGroup
        shelfmateGroupType
        shelfmateImageTransparent {
          title
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        thumbnailVariantsImages: variantsImages {
          variantTitle
          productName
          images {
            title
            fluid(maxWidth: 700, quality: 50) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          thumbnailforHover {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          thumbnailVariantColor {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
        shopifyData {
          variants {
            compare_at_price
            inventory_quantity
            price
            title
            sku
            id
            product_id
          }
        }
        productTag
        thumbnailImages {
          fluid {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`;
